import { categoryList } from '../api/index';
import{ getImageCategory } from '../util/anyFunctions'

const useCategoryList = async(type) =>{
    const response = await categoryList({type});
    let categories = []
    if(response.status !== "fail"){
        categories = response?.data?.categories?.map(({value, name}) => ({
            value: value, label: name, image: getImageCategory(value)
        }));
    }
    if(type !== 'all' && response.status !== "fail"){
        categories.unshift({value:'available', label: "Todos los servicios"});
    }
    return {categories}
}

export default useCategoryList;